import * as React from "react";
import { Suspense, lazy } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { Header, Footer, HelmetCust } from "../components";
import { ReactNotifications } from "react-notifications-component";
import "./routes.scss";
import { ScrollToTop } from "../utils/commonFunctions/ScrollToTop";
import { BouncingDotsLoader } from "../components/widgets";
import { RoutingURLs } from "../config/RoutingURLs";
import ProtectedRoutes from "./ProtectedRoutes";
import { useAppSelector } from "../app/hooks";
import EnvConfig from "../config/env.config.json";
const LazyLogin = lazy(() => import("../pages/Login"));
const LazyCheckout = lazy(() => import("../pages/Checkout"));
const LazySignUp = lazy(() => import("../pages/SignUp"));
const LazyGiveBack = lazy(() => import("../pages/Giveback"));
const LazyBestFit = lazy(() => import("../pages/BestFit"));
const LazyReviews = lazy(() => import("../pages/Reviews"));
const LazyImeiCompatibilityCheck = lazy(
  () => import("../pages/CompatibilityCheck")
);
const LazyTerms = lazy(() => import("../pages/Terms"));
const LazyULPromoTerms = lazy(() => import("../pages/ULPromoTerms"));
const LazyPrivacy = lazy(() => import("../pages/Privacy"));
const LazyPlans = lazy(() => import("../pages/Plans"));
const LazyAboutUs = lazy(() => import("../pages/AboutUs"));
const LazyHome = lazy(() => import("../pages/Home"));
const LazyInternationalServices = lazy(
  () => import("../pages/InternationServices")
);
const LazyContactUs = lazy(() => import("../pages/ContactUs"));
const LazyServices = lazy(() => import("../pages/Services"));
const LazyCoverageCheck = lazy(() => import("../pages/CoverageCheck"));
const LazyBillCalculator = lazy(() => import("../pages/BillCalculator"));
const LazyProfile = lazy(() => import("../pages/PostPurchase/Profile"));
const LazyPostHome = lazy(() => import("../pages/PostActivationPages/PAHome"));
const LazyHelpCenter = lazy(
  () => import("../pages/PostActivationPages/HelpCenter")
);
const LazyHelp = lazy(() => import("../pages/Help"));
const LazyLoaderPage = lazy(() => import("../pages/Loader"));
const LazyAccount = lazy(() => import("../pages/PostActivationPages/Account"));
const LazyTrackOrder = lazy(() => import("../pages/TrackOrder"));
const LazyDeviceConfirmCheck = lazy(() => import("../pages/PostPurchase/DeviceCheckConfim"));
const LazyDiscover = lazy(() => import("../pages/Discover"));
const LazyBilling = lazy(() => import("../pages/PostActivationPages/Billing"));
const LazyMyAccount = lazy(() => import("../pages/PostPurchase/MyAccount"));
const LazyActivation = lazy(
  () => import("../pages/PostActivationPages/Activation")
);
const LazySecondaryUserHome = lazy(() => import("../pages/SecondaryUserHome"));
const LazyActivationSteps = lazy(() => import("../pages/ActivationSteps"));
const LazyActivateEsim = lazy(() => import("../pages/ActivateEsim"));
const LazySettings = lazy(() => import("../pages/Settings"));
const LazyWallet = lazy(() => import("../pages/wallet"));
const LazyULPromo = lazy(() => import("../pages/ULPromo"));
const LazyCoverageMap = lazy(() => import("../pages/CoverageMap"));
const LazySafeConnection = lazy(() => import("../pages/SafeConnection"));
const AppRoutes: React.FC = () => {
  const { seoData } = useAppSelector(
    (state: any) => state?.strapi?.commonPage || {}
  );
  return (
    <>
      <BrowserRouter>
        <Header />
        <ReactNotifications />
        <ScrollToTop />

        <div className="mainRoutes">
          <HelmetCust helmetPageName={"home"} />
          <Routes>
            <Route
              path="*"
              element={<Navigate to={RoutingURLs.default} replace />}
            />
            <Route
              path={RoutingURLs.default}
              element={
                <Suspense>
                  <LazyHome />
                </Suspense>
              }
            />
            <Route
              path={RoutingURLs.home}
              element={
                <Suspense>
                  <LazyHome />
                </Suspense>
              }
            />
            <Route
              path={RoutingURLs.aboutUs}
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  <LazyAboutUs />
                </Suspense>
              }
            />
            <Route
              path={RoutingURLs.internationalServices}
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  <LazyInternationalServices />
                </Suspense>
              }
            />
            {/* Prelogin routes */}
            <Route
              path={RoutingURLs.plans}
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  <LazyHome />
                </Suspense>
              }
            />
            <Route
              path={RoutingURLs.cellPhonePlans}
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  <LazyPlans />
                </Suspense>
              }
            />
            <Route
              path={RoutingURLs.bestfit}
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  <LazyBestFit />
                </Suspense>
              }
            />
            <Route
              path={RoutingURLs.reviews}
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  <LazyReviews />
                </Suspense>
              }
            />
            <Route
              path={RoutingURLs.imeiCheck}
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  <LazyImeiCompatibilityCheck />
                </Suspense>
              }
            />
            <Route
              path={RoutingURLs.terms}
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  <LazyTerms />
                </Suspense>
              }
            />
            <Route
              path={RoutingURLs.safeConnection}
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  <LazySafeConnection />
                </Suspense>
              }
            />
            <Route
              path={RoutingURLs.unlimitedBundleTerms}
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  <LazyULPromoTerms />
                </Suspense>
              }
            />
            <Route
              path={RoutingURLs.privacy}
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  <LazyPrivacy />
                </Suspense>
              }
            />

            <Route
              path={RoutingURLs.giveBack}
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  <LazyGiveBack />
                </Suspense>
              }
            />
            <Route
              path={RoutingURLs.login}
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  <LazyLogin />
                </Suspense>
              }
            />
            <Route
              path={RoutingURLs.checkout}
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  <LazyCheckout />
                </Suspense>
              }
            />
            <Route
              path={RoutingURLs.signUp}
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  <LazySignUp />
                </Suspense>
              }
            />
            <Route
              path={RoutingURLs.contactUs}
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  <LazyContactUs />
                </Suspense>
              }
            />
            <Route
              path={RoutingURLs.coverageCheck}
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  <LazyCoverageCheck />
                </Suspense>
              }
            />
            { EnvConfig.IS_COVERAGE_MAP_PAGE_ENABLED &&
              <Route
                path={RoutingURLs.coverageMap}
                element={
                  <Suspense fallback={<BouncingDotsLoader />}>
                    <LazyCoverageMap />
                  </Suspense>
                }
              />
            }
            <Route
              path={RoutingURLs.billCalculator}
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  <LazyBillCalculator />
                </Suspense>
              }
            />
            <Route
              path={RoutingURLs.help}
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  <LazyHelp />
                </Suspense>
              }
            />
            <Route
              path={RoutingURLs.helpCenter}
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  <LazyHelpCenter />
                </Suspense>
              }
            />
            <Route
              path={RoutingURLs.discover}
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  <LazyDiscover />
                </Suspense>
              }
            />
            <Route
              path={RoutingURLs.ulPromo}
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  <LazyULPromo />
                </Suspense>
              }
            />
            <Route element={<ProtectedRoutes />}>
              <Route
                path={RoutingURLs.profile}
                element={
                  <Suspense fallback={<BouncingDotsLoader />}>
                    <LazyProfile />
                  </Suspense>
                }
              />
              <Route
                path={RoutingURLs.postActivationHome}
                element={
                  <Suspense fallback={<BouncingDotsLoader />}>
                    <LazyPostHome />
                  </Suspense>
                }
              />
              <Route
                path={RoutingURLs.myAccount}
                element={
                  <Suspense fallback={<BouncingDotsLoader />}>
                    <LazyMyAccount />
                  </Suspense>
                }
              />
              <Route
                path={RoutingURLs.trackOrder}
                element={
                  <Suspense fallback={<BouncingDotsLoader />}>
                    <LazyTrackOrder />
                  </Suspense>
                }
              />
              <Route
                path={RoutingURLs.deviceConfirmCheck}
                element={
                  <Suspense fallback={<BouncingDotsLoader />}>
                    <LazyDeviceConfirmCheck />
                  </Suspense>
                }
              />
              <Route
                path={RoutingURLs.activateEsim}
                element={
                  <Suspense fallback={<BouncingDotsLoader />}>
                    <LazyActivateEsim />
                  </Suspense>
                }
              />
              <Route
                path={RoutingURLs.secondarylogin}
                element={
                  <Suspense fallback={<BouncingDotsLoader />}>
                    <LazySecondaryUserHome />
                  </Suspense>
                }
              />
              <Route
                path={RoutingURLs.steps}
                element={
                  <Suspense fallback={<BouncingDotsLoader />}>
                    <LazyActivationSteps />
                  </Suspense>
                }
              />
              <Route
                path={RoutingURLs.activation}
                element={
                  <Suspense fallback={<BouncingDotsLoader />}>
                    <LazyActivation />
                  </Suspense>
                }
              />
              <Route
                path={RoutingURLs.account}
                element={
                  <Suspense fallback={<BouncingDotsLoader />}>
                    <LazyAccount />
                  </Suspense>
                }
              />
              <Route
                path={RoutingURLs.billing}
                element={
                  <Suspense fallback={<BouncingDotsLoader />}>
                    <LazyBilling />
                  </Suspense>
                }
              />
              <Route
                path={RoutingURLs.services}
                element={
                  <Suspense fallback={<BouncingDotsLoader />}>
                    <LazyServices />
                  </Suspense>
                }
              />
              <Route
                path={RoutingURLs.settings}
                element={
                  <Suspense fallback={<BouncingDotsLoader />}>
                    <LazySettings />
                  </Suspense>
                }
              />
              <Route
                path={RoutingURLs.loader}
                element={
                  <Suspense fallback={<BouncingDotsLoader />}>
                    <LazyLoaderPage />
                  </Suspense>
                }
              />
              <Route
                path={RoutingURLs.wallet}
                element={
                  <Suspense fallback={<BouncingDotsLoader />}>
                    <LazyWallet />
                  </Suspense>
                }
              />
            </Route>
            {/* <Route
              path="/byod"
              element={<Navigate to="/imei-compatibility-check" replace />}
            />
            <Route
              path="/chat"
              element={<Navigate to="/#hs-chat-open" replace />}
            />
          {/* 
            <Route
              path="/recover"
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  {" "}
                  <LazyRecoverPassword />{" "}
                </Suspense>
              }
            />
            <Route
              path="/settings"
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  <LazySettings />
                </Suspense>
              }
            />
            <Route
              path="/basic-plan"
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  {" "}
                  <LazyLandingNew history={" "} />{" "}
                </Suspense>
              }
            />
            <Route
              path="/moderate-plan"
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  {" "}
                  <LazyLandingNew history={" "} />{" "}
                </Suspense>
              }
            />
            <Route
              path="/people-choice-plan"
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  {" "}
                  <LazyLandingNew history={" "} />{" "}
                </Suspense>
              }
            />
            <Route
              path="/all-in-plan"
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  {" "}
                  <LazyLandingNew history={" "} />{" "}
                </Suspense>
              }
            />
            <Route
              path="/lp01-allplans"
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  {" "}
                  <LazyLandingNew history={" "} />{" "}
                </Suspense>
              }
            />
            <Route
              path="/lp01-giveback"
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  {" "}
                  <LazyLandingGiveBack history={" "} />{" "}
                </Suspense>
              }
            />
            <Route
              path="/lp01-individual"
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  {" "}
                  <LazyLandingIndividual history={" "} />{" "}
                </Suspense>
              }
            /> */}
            {/* <Route
              path="/lp01-bestfit"
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  {" "}
                  <LazyLandingBestFit history={" "} />{" "}
                </Suspense>
              }
            /> */}
            {/* <Route
              path="/lp01-crossout"
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  {" "}
                  <LazyLandingCrossFit history={" "} />{" "}
                </Suspense>
              }
            /> */}
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </>
  );
};
export default AppRoutes;
